
















import _ from "lodash"
import { defineComponent } from "@vue/composition-api"

import TeamTag from "@/components/GroupTeams/Common/TeamTag.vue"
import MeetingSmartGrid from "@/components/GroupTeams/Common/GameModes/MeetingSmartGrid.vue"

export default defineComponent({
  name: "MeetingGroupWithTag",
  components: {
    TeamTag,
    MeetingSmartGrid
  },
  props: {
    maxSize: {
      type: Number,
      default: 500
    },
    minSize: {
      type: Number,
      default: 160
    },
    users: {
      type: Array,
      required: true
    },
    group: {
      type: Object,
      required: true
    }
  },
  methods: {
    select(e) {
      this.$emit("select", e)
    }
  }
})
