var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting-with-breakouts", class: { "pb-5": _vm.isSlides } },
    [
      _c(
        "div",
        { staticClass: "meeting-with-breakouts__info-and-viwer-group" },
        [
          _c(
            "MissionCard",
            {
              staticClass: "meeting-with-breakouts__info",
              class: _vm.pinnedUser
                ? "meeting-with-breakouts__info--with-pinned-user"
                : null,
            },
            [
              _c(
                "div",
                { staticClass: "meeting-with-breakouts__pinned-users" },
                [
                  _vm.host
                    ? _c(
                        "MeetingUserCard",
                        {
                          staticClass: "meeting-with-breakouts__pinned-user",
                          attrs: {
                            user: _vm.host,
                            selectable: "",
                            badge: false,
                            name:
                              _vm.hostOverlayText != null ? null : undefined,
                          },
                        },
                        [
                          _vm.hostOverlayText
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "meeting-with-breakouts__pinned-user-overlay",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.hostOverlayText) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm.pinnedUser
                    ? _c("MeetingUserCard", {
                        staticClass: "meeting-with-breakouts__pinned-user",
                        attrs: { user: _vm.pinnedUser, pinUI: "" },
                        on: { onPin: _vm.unpin },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("ResizableText", {
                staticClass: "meeting-with-breakouts__info-text",
                attrs: { message: _vm.text },
              }),
            ],
            1
          ),
          _c("MeetingGroupWithTag", {
            key: _vm.viewerGroup.id,
            attrs: {
              group: _vm.viewerGroup,
              users: _vm.viewerGroup.users,
              maxSize: 500,
              minSize: 160,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "meeting-with-breakouts__other-groups",
          on: { mouseleave: _vm.onMouseLeave, mouseenter: _vm.onMouseEnter },
        },
        [
          !_vm.disabledLeftArrow
            ? _c("SvgIcon", {
                staticClass:
                  "meeting-with-breakouts__arrow meeting-with-breakouts__arrow--left",
                attrs: { name: "chevron-left-regular", size: "large" },
                on: { click: _vm.decrement },
              })
            : _vm._e(),
          !_vm.disabledRightArrow
            ? _c("SvgIcon", {
                staticClass:
                  "meeting-with-breakouts__arrow meeting-with-breakouts__arrow--right",
                attrs: { name: "chevron-right-regular", size: "large" },
                on: { click: _vm.increment },
              })
            : _vm._e(),
          _vm._l(_vm.otherGroups, function (group) {
            return _c("MeetingGroupWithTag", {
              key: group.id,
              attrs: {
                group: group,
                users: group.users,
                maxSize: 500,
                minSize: 100,
              },
            })
          }),
        ],
        2
      ),
      _vm.isSlides
        ? _c("DownloadPdf", {
            staticClass: "mx-auto self-center my-2 absolute bottom-0",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }