



















































































import _, { CollectionChain } from "lodash"
import { defineComponent, computed, ref } from "@vue/composition-api"

import User from "@shared/User"
import Team from "@shared/Team"
import Mission from "@shared/enums/Mission"

import UserMedia from "@/components/GroupTeams/Common/User/UserMedia.vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import MeetingGroupWithTag from "@/components/GroupTeams/Common/GameModes/Meeting/MeetingGroupWithTeamTag.vue"
import MeetingUserCard from "@/components/GroupTeams/Common/Player/MeetingUserCard/MeetingUserCard.vue"

import useStore from "@/use/useStore"
import useTeamScroller from "@/components/Main/useTeamScroller"

import { db } from "@/firebase"

const MAX_LENGTH_OF_OTHER_GROUPS = 3

export default defineComponent({
  name: "MeetingWithBreakouts",
  components: {
    DownloadPdf: () =>
      import(
        "@/components/GroupTeams/Common/Games/GameCardParts/DownloadPdf.vue"
      ),
    UserMedia,
    ResizableText,
    MissionCard,
    MeetingGroupWithTag,
    MeetingUserCard
  },
  computed: {
    hostOverlayText() {
      const teamId = this.$store.getters["group/globalTeamID"]
      const hostCurrentTeam = this.teams.find(team => team.id === teamId)
      const isHostAutoMuted =
        !this.isViewerHostLike &&
        this.$store.getters["group/isUserMutedByRoomLogic"](this.host) &&
        !this.host?.muted

      if (!this.host) return null
      if (!isHostAutoMuted) return null
      if (!hostCurrentTeam?.name) return null

      return `${User.getShortenedName(this.host)} is talking to ${
        hostCurrentTeam.name
      }`
    }
  },
  setup() {
    const { store } = useStore()

    const mission = computed(() => store.getters.getCurrentMission)
    const isSlides = computed(() => mission.value?.behavior === Mission.Slides)

    const text = computed(() => mission.value?.instructions)
    const viewer = computed(() => store.getters["auth/user"])
    const primaryHost = computed(() => ({
      ...store.getters.gameHosts[0],
      selected: true
    }))
    const otherHosts = computed(() => store.getters.gameHosts.slice(1))
    const pinnedUserID = computed(() => store.getters.gameStatus?.pinedUserID)
    const users = computed(() => store.getters.onlineUsersArray)
    const isViewerHostLike = computed(
      () => store.getters["group/isViewerHostLike"]
    )
    const isUserHostLike = computed(() => store.getters["group/isUserHostLike"])
    const pinnedUser = computed(() =>
      users.value.find(
        user => user.id === pinnedUserID.value && !isUserHostLike.value(user)
      )
    )
    const teams = computed(() => Team.normalize(store.getters.chats || {}))
    const roomId = computed(() => store.getters.game?.id)

    const viewerGroupId = computed(() => {
      if (isViewerHostLike.value) return store.getters["group/globalTeamID"]
      return viewer.value.teamID
    })

    const hasRaisedHand = computed(() => {
      return user => user.raisehand?.missionID === mission.value.id
    })

    const pauseAutoScroll = computed(() => {
      if (!isViewerHostLike.value) return false
      const isUserHostLike = store.getters["group/isUserHostLike"]
      return users.value.some(
        user =>
          hasRaisedHand.value(user) &&
          !User.isSpecialPurposeUser(user) &&
          User.isPlayer(user) &&
          User.isPlaying(user) &&
          !isUserHostLike(user)
      )
    })

    const groups = computed(() => {
      const isUserHostLike = store.getters["group/isUserHostLike"]

      const groups: CollectionChain<{ users: unknown[]; id: string }> = _.chain(
        users.value
      )
        .filter(
          user =>
            !User.isSpecialPurposeUser(user) &&
            User.isPlayer(user) &&
            User.isPlaying(user) &&
            !isUserHostLike(user) &&
            user.id !== pinnedUserID.value
        )
        .sortBy("id")
        .groupBy("teamID")
        .map((value, key) => ({
          ...teams.value.find(team => team.id === key),
          users: value
        }))

      let values

      if (isViewerHostLike.value) {
        values = groups
          .sortBy(group => !group.users.some(hasRaisedHand.value))
          .sortBy(group => group.id !== viewerGroupId.value)
          .value()
      } else {
        values = groups
          .sortBy(group => group.id !== viewerGroupId.value)
          .value()
      }

      if (otherHosts.value.length > 0)
        values.push({
          id: 0,
          name: otherHosts.value.reduce((acc, val, index) => {
            if (index > 0) return acc + ", " + val.firstname
            return acc + val.firstname
          }, ""),
          users: otherHosts.value
        })

      return values
    })

    const viewerGroup = computed(() => groups.value[0])
    const otherGroups = computed(() => groups.value.slice(1))

    const maxLengthOfOtherGroups = ref(MAX_LENGTH_OF_OTHER_GROUPS)

    const {
      index,
      disabledLeftArrow,
      disabledRightArrow,
      increment,
      decrement,
      onMouseLeave,
      onMouseEnter
    } = useTeamScroller(
      otherGroups,
      maxLengthOfOtherGroups,
      null,
      pauseAutoScroll
    )

    const otherGroupsTruncated = computed(() => {
      const n = Math.min(index.value, otherGroups.value.length - 1)
      return otherGroups.value.slice(n, n + MAX_LENGTH_OF_OTHER_GROUPS)
    })

    function unpin() {
      db.auxiliary()
        .ref(
          `org/${store.getters.orgID}/game/${roomId.value}/gameStatus/pinedUserID`
        )
        .set(null)
    }

    return {
      isSlides,
      isViewerHostLike,
      teams,
      pinnedUserID,
      viewerGroup,
      otherGroups: otherGroupsTruncated,
      index,
      disabledLeftArrow,
      disabledRightArrow,
      increment,
      decrement,
      onMouseLeave,
      onMouseEnter,
      text,
      host: primaryHost,
      pinnedUser,
      unpin
    }
  }
})
