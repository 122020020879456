var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting-group-with-team-tag" },
    [
      _c("TeamTag", {
        staticClass: "meeting-group-with-team-tag__team-tag",
        attrs: { team: _vm.group },
      }),
      _c(
        "div",
        { staticClass: "meeting-group-with-team-tag__grid" },
        [
          _c("MeetingSmartGrid", {
            attrs: {
              maxWidth: _vm.maxSize,
              minWidth: _vm.minSize,
              users: _vm.users,
              pinUI: "",
              showPinned: false,
            },
            on: { select: _vm.select },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }