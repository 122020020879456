import {
  computed,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  watch
} from "@vue/composition-api"

const AUTO_UPDATE_INDEX_INTERVAL: number = 10 * 1000

// TODO: rename so it's an abstract scroller

export default function useTeamScroller(
  items: Ref<unknown[]>,
  max: Ref<number>,
  disabled?: Ref<boolean>,
  paused?: Ref<boolean>
) {
  const index = ref(0)
  const interval = ref<ReturnType<typeof setInterval>>(null)
  const length = computed<number>(() => items.value?.length)

  if (disabled !== undefined) {
    watch(disabled, value => {
      if (value === false) return
      index.value = 0
    })
  }

  const disabledLeftArrow = computed<boolean>(() => {
    if (length?.value < 2) return true
    return disabled?.value || index.value < 1
  })

  const disabledRightArrow = computed<boolean>(() => {
    if (length?.value < 2) return true
    return disabled?.value || index?.value > length?.value - max?.value - 1
  })

  function autoUpdateIndex(): void {
    interval.value = setInterval(() => {
      if (paused?.value) return
      if (length.value > max.value) increment()
    }, AUTO_UPDATE_INDEX_INTERVAL)
  }

  function increment(): void {
    if (disabled?.value) return
    index.value = (index.value + 1) % (length.value - max.value + 1)
  }

  function decrement(): void {
    if (disabled?.value) return
    index.value = index.value - 1
  }

  function onMouseLeave(): void {
    if (disabled?.value) return
    autoUpdateIndex()
  }

  function onMouseEnter(): void {
    if (disabled?.value) return
    clearInterval(interval.value)
  }

  onMounted(() => {
    if (disabled?.value) return
    autoUpdateIndex()
  })

  onBeforeUnmount(() => clearInterval(interval.value))

  return {
    index,
    increment,
    decrement,
    onMouseLeave,
    onMouseEnter,
    disabledLeftArrow,
    disabledRightArrow
  }
}
